import React, { useState } from "react";
import { Link } from "react-router-dom";

import ErrorBar from "../../../components/ErrorBar";
import ReadMore from "../../../components/ReadMore/ReadMore";
import { dateFormat, timeFormat } from "../../../handlers/functions";
import { approvePostByID, deletePostByID } from "../../api/posts";

function FeedPost({ post, refetch }) {
	//todo work on reactions. Like, comment, and save

	function highlight() {
		console.log("highlight");
	}

	const [approveLoading, setApproveLoading] = useState(false);

	function approvePost() {
		const postID = post._id;

		setApproveLoading(true);
		approvePostByID(postID, (response) => {
			if (response.ok === 1) {
				setTimeout(() => {
					refetch();
				}, 1000);
				setApproveLoading(false);
			} else {
				setApproveLoading(false);
				console.log(response.error);
			}
		});
	}

	const [deleteLoading, setDeleteLoading] = useState(false);

	function deletePost() {
		setDeleteLoading(true);
		const postID = post._id;

		deletePostByID(postID, (response) => {
			if (response.ok === 1) {
				setDeleteLoading(false);
				setTimeout(() => {
					refetch();
				}, 1000);
			} else {
				setDeleteLoading(false);
				console.log(response.error);
			}
		});
	}

	const [error] = useState("");
	const [showErrors, setShowErrors] = useState(false);

	const [showCommentBox, setShowCommentBox] = useState(false);

	return (
		<div className="rounded bg-white !p-3 shadow-sm mb-4">
			<div className="flex items-center justify-between space-x-4">
				<div>
					<h4>{post.title}</h4>
					<p className="m-0 text-sm text-gray-600">
						By Alumni{" "}
						<Link className="user" to={"/u/" + post.user.slug}>
							{post.user.username.split(" ")[0]}
						</Link>{" "}
						posted to{" "}
						<Link className="user" to={"/c/" + post.community.slug}>
							{post.community.name}
						</Link>
					</p>
					<div>
						<p className="text-gray-500 text-sm">{dateFormat(post.timestamp)}</p>
					</div>
				</div>
				<div className="post-actions single-row">
					{!post.approved && (
						<>
							<button className="btn btn-sm button bg-success" onClick={approvePost}>
								{approveLoading ? (
									<div className="spinner-border" role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								) : (
									<>
										Approve <i className="fa-solid fa-check" />
									</>
								)}
							</button>
							&nbsp;
						</>
					)}

					<button className="btn btn-sm button bg-danger" onClick={deletePost}>
						{deleteLoading ? (
							<div className="spinner-border" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						) : (
							<>
								Delete <i className="fa-solid fa-trash" />
							</>
						)}
					</button>
				</div>
			</div>
			<div className="post-content">
				<ReadMore>{post.content}</ReadMore>
			</div>
			<div className="post-footer">
				<div className="icon-group" onClick={() => setShowCommentBox(!showCommentBox)}>
					<div className="icon comment">
						<i className="fa-solid fa-comment" />
					</div>
					<div className="count">{post.comments.length}</div>
				</div>
				<div className="icon-group">
					<div className="icon save" onClick={highlight}>
						<i className="fa-solid fa-bookmark" />
					</div>
				</div>
			</div>
			{showCommentBox ? (
				<>
					{post.comments && post.comments.length > 0 ? (
						<div className="comments">
							{[...post.comments].reverse().map((comment) => (
								<div className="comment rounded bg-white p-3 shadow-sm mb-2" key={comment._id}>
									<div className="post-header">
										<p>By Alumni {comment.user.username.split(" ")[0]}</p>
										<div>
											<span className="timestamp">
												{dateFormat(comment.timestamp)} {" - "}
												{timeFormat(comment.timestamp)}
											</span>
										</div>
									</div>
									<div className="comment-content">
										<ReadMore>{comment.comment}</ReadMore>
									</div>
								</div>
							))}
						</div>
					) : (
						<div className="no-comments">No comments yet.</div>
					)}
				</>
			) : null}
			{error && <ErrorBar error={error} show={showErrors} onClose={() => setShowErrors(!showErrors)} />}
		</div>
	);
}

export default FeedPost;
