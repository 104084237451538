import React, { useState } from "react";
import { Link } from "react-router-dom";

function ReadMore({ children, link }) {
	const text = children;
	const [isReadMore, setIsReadMore] = useState(true);
	const toggleReadMore = () => {
		if (link) window.location.href = link;
		setIsReadMore(!isReadMore);
	};
	const postContent = isReadMore ? text.slice(0, 250) : text;

	return (
		<div className="post-content m-0">
			<span dangerouslySetInnerHTML={{ __html: postContent }} />
			{text.length > 250 && (
				<>
					{link ? (
						<>
							...
							<div className="not-full-width">
								<Link to={link} className="mb-3 btn btn-sm button">
									Read More
								</Link>
							</div>
						</>
					) : (
						<span onClick={toggleReadMore} className="read-more">
							{isReadMore ? "...Read More" : " Show less"}
						</span>
					)}
				</>
			)}
		</div>
	);
}

export default ReadMore;
