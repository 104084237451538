import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

import { bookmarkPost } from "../api/user";
import AuthContext from "../auth/context";
import Loader from "../components/Loader";
import { dateFormat, isValidEmail } from "../handlers/functions";
import { useJobBySlug } from "../queries/jobs.query";
import NotFoundScreen from "./NotFoundScreen";

function JobScreen() {
	const [saved, setSaved] = useState(false);

	const { user } = useContext(AuthContext);
	const { userID } = user.user;

	function bookmark() {
		setSaved(true);
		bookmarkPost(
			{
				userID,
				postData: job,
				postType: "job",
			},
			(response) => {
				if (response.ok === 1) {
					setSaved(true);
					return;
				}
				toast.error("Something went wrong. Please try again.");
			},
		);
	}

	const [job, setJob] = useState(null);
	const { data, isLoading } = useJobBySlug();

	useEffect(() => {
		if (isLoading) return;
		setJob(data.response[0]);
	}, [data]);

	useEffect(() => {
		if (job) {
			document.title = job?.title;
		}
	}, [job]);

	if (isLoading) {
		return (
			<div className="">
				<div
					style={{
						height: "70vh",
					}}
				>
					<Loader />
				</div>
			</div>
		);
	}

	return (
		<div className="">
			{job ? (
				<div className="rounded bg-white p-3 shadow-sm mb-2">
					<div className="single-row mb-0">
						<h4 className="mb-0">{job.title}</h4>
						<a
							href={isValidEmail(job.url) ? `mailto:${job.url}` : job.url}
							className="btn button button-alt btn-sm"
							target="_blank"
							rel="noopener noreferrer"
						>
							Apply <i className="fa-solid fa-arrow-up-right-from-square" />
						</a>
					</div>
					<div className="header-details single-row my-3">
						<div className="left">
							<div>
								<i className="fa-solid mx-2 fa-building" /> {job.company}
							</div>
							<div>
								<i className="fa-solid mx-2 fa-location-dot" /> {job.location}
							</div>
							<div>
								<i className="mx-2 fa-solid fa-calendar-days" /> {dateFormat(job.applicationDeadline)}
							</div>
						</div>
						<div className="right text-end">
							<div>
								<strong>Posted on </strong>
								{dateFormat(job.timestamp)}
							</div>
							<p>
								<strong>Posted by alumni </strong> {job.user.username.split(" ")[0]}
							</p>
						</div>
					</div>

					<span
						dangerouslySetInnerHTML={{
							__html: job.description,
						}}
					/>

					<div className="post-footer">
						<div className="icon-group">
							<div className="count">Bookmark</div>
							<div className={`icon save ${saved && "saved-post"}`} onClick={bookmark}>
								<i className="fa-solid fa-bookmark" />
							</div>
						</div>
					</div>
				</div>
			) : (
				<NotFoundScreen />
			)}
		</div>
	);
}

export default JobScreen;
