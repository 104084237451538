import axios from "axios";

import authStorage from "../../auth/storage";
import { FRONTEND_API_KEY } from "../../config/api";
import { API_BASE_URL } from "../../config/constants";

const storedUser = authStorage.getUser();

export function getUnaprovedPosts(page, callback) {
	const config = {
		method: "get",
		url: API_BASE_URL + "/posts?page=" + page,
		headers: {
			"X-API-Key": FRONTEND_API_KEY,
			Authorization: "Bearer " + storedUser.token,
		},
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function approvePostByID(id, callback) {
	const config = {
		method: "patch",
		url: API_BASE_URL + "/posts?id=" + id,
		headers: {
			"X-API-Key": FRONTEND_API_KEY,
			Authorization: "Bearer " + storedUser.token,
		},
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function deletePostByID(id, callback) {
	var config = {
		method: "delete",
		url: API_BASE_URL + "/posts?id=" + id,
		headers: {
			"X-API-Key": FRONTEND_API_KEY,
			Authorization: "Bearer " + storedUser.token,
		},
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}
