import dayjs from "dayjs";
import React from "react";
import toast from "react-hot-toast";

import AuthContext from "../../auth/context";

function Feedback() {
	const [showFeebackModal, setShowFeedbackModal] = React.useState(false);

	const [feedbackData, setFeedbackData] = React.useState({
		type: "", // bug, feature, general
		message: "",
	});

	const { user } = React.useContext(AuthContext);

	const handleSubmit = (e) => {
		e.preventDefault();

		if (feedbackData.type === "") {
			toast.error("Please select a type");
			return;
		}

		if (feedbackData.message.length < 3) {
			toast.error("Message is too short");
			return;
		}

		const data = {
			Type: feedbackData.type,
			Message: feedbackData.message,
			Date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
			Email: user.user.email,
			Page: window.location.pathname,
		};

		const URL =
			"https://script.google.com/macros/s/AKfycbw9KZFTbZwFQUy1Ve-60w1xfcWI0VnOGgWtmdoc9R6Jkvj3LY4dTCB7-_7SbCSSX17N/exec";

		fetch(URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
			body: JSON.stringify(data),
			mode: "no-cors",
		})
			.then(() => {
				toast.success("Feedback submitted successfully");
				setShowFeedbackModal(false);
			})
			.catch(() => {
				toast.error("Something went wrong. Please try again later.");
			});
	};

	return (
		<>
			<button
				className="absolute border bg-white -rotate-90 origin-bottom-right bottom-[200px] rounded-t-lg right-0 z-30 px-3 py-2"
				onClick={() => setShowFeedbackModal(!showFeebackModal)}
			>
				Feedback
			</button>

			{showFeebackModal && (
				<div className="bg-black/40 position-fixed z-50 top-0 left-0 h-screen w-screen flex items-center justify-center">
					<div className="bg-white rounded p-3 w-[600px] max-w-[90%]">
						<div className="mb-3 flex justify-between">
							<h4 className="m-0">Feedback</h4>
							<button onClick={() => setShowFeedbackModal(false)}>
								<i className="fa fa-x text-gray-400" />
							</button>
						</div>

						<form action="" onSubmit={handleSubmit}>
							<div className="mb-3">
								<label htmlFor="type">Type</label>
								<select
									name="type"
									id="type"
									className="form-control"
									onChange={(e) => setFeedbackData({ ...feedbackData, type: e.target.value })}
								>
									<option value="">Select type</option>
									<option value="bug">Bug / Problem</option>
									<option value="feature">Feature</option>
									<option value="general">General</option>
								</select>
							</div>

							<div className="mb-3">
								<label htmlFor="message">Message</label>
								<textarea
									name="message"
									className="form-control"
									id="message"
									onChange={(e) => setFeedbackData({ ...feedbackData, message: e.target.value })}
								/>
							</div>

							<button type="submit" className="button btn" onClick={handleSubmit}>
								Submit
							</button>
						</form>
					</div>
				</div>
			)}
		</>
	);
}

export default Feedback;
