import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Loader from "../components/Loader";
import { dateFormat, timeFormat } from "../handlers/functions";
import { useEventBySlug } from "../queries/events.query";
import NotFoundScreen from "./NotFoundScreen";

function EventScreen() {
	const [event, setEvent] = useState(null);

	const { data, isLoading } = useEventBySlug();

	useEffect(() => {
		if (isLoading) return;
		if (data) {
			setEvent(data.response[0]);
		}
	}, [data]);

	return (
		<div className="">
			{isLoading ? (
				<div className="h-[80vh]">
					<Loader />
				</div>
			) : (
				<>
					{event ? (
						<div className="event">
							<div className="rounded bg-white p-3 shadow-sm mb-2">
								<div className="card-body">
									<h4>{event.title}</h4>
									<div className="header-details">
										<div>
											<i className="fa-solid fa-location-dot" /> {event.location}
										</div>
										<div>
											<i className="fa-solid fa-clock" /> {dateFormat(event.dateTime)} - {timeFormat(event.dateTime)}
										</div>
										<p>
											Posted By: <Link to={`/u/${event.user.slug}`}>{event.user.name}</Link>
										</p>
									</div>

									<span
										dangerouslySetInnerHTML={{
											__html: event.description,
										}}
									/>
								</div>
							</div>
						</div>
					) : (
						<NotFoundScreen />
					)}
				</>
			)}
		</div>
	);
}

export default EventScreen;
