import QueryString from "qs";

import Api from "../../config/api";

export async function fetchEvents(page) {
	const { data } = await Api.get(`/events?page=${page}`);
	return data;
}

export async function getEventsBySlug(slug) {
	const { data } = await Api.get(`/events/${slug}`);
	return data;
}

export async function addEvent(requestData) {
	var reqData = QueryString.stringify(requestData);

	const { data } = await Api.post("/events", reqData);
	return data;
}
