import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import { deletePostByID } from "../admin/api/posts";
import { commentOnPost, getPostsBySlug } from "../api/post";
import { bookmarkPost } from "../api/user";
import AuthContext from "../auth/context";
import ErrorBar from "../components/ErrorBar";
import Loader from "../components/Loader";
import ReadMore from "../components/ReadMore";
import { dateFormat, timeFormat } from "../handlers/functions";
import NotFoundScreen from "./NotFoundScreen";

function PostsScreen() {
	// get slug from url
	const { slug } = useParams();

	const [post, setPost] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	if (!slug) {
		window.location.href = "/communities";
	}

	const [showCommentBox, setShowCommentBox] = useState(false);

	const [comment, setComment] = useState("");
	function handleCommentChange(e) {
		setComment(e.target.value);
	}

	const { user } = useContext(AuthContext);
	const { userID } = user.user;

	function fetchPostData() {
		setIsLoading(true);
		getPostsBySlug(slug, (response) => {
			if (response.ok === 1) {
				setPost(response.response[0]);
				setIsLoading(false);
			} else {
				setIsLoading(false);
				toast.error("An error occurred. Please try again");
			}
		});
	}

	const [error, setErrors] = useState("");
	const [showErrors, setShowErrors] = useState(false);

	function handleCommentSubmit(e) {
		e.preventDefault();
		if (comment.length === 0) {
			setErrors("You can't send an empty comment.");
			setShowErrors(true);
			return;
		}
		const commentData = {
			userID,
			comment,
		};

		commentOnPost(post._id, commentData, (response) => {
			if (response.ok === 1) {
				setComment("");
				fetchPostData();
				return;
			}
			setErrors("Something went wrong. Please try again.");
			setShowErrors(true);
		});
	}

	useEffect(() => {
		fetchPostData();
	}, []);

	const [deleteLoading, setDeleteLoading] = useState(false);

	function deletePost() {
		setDeleteLoading(true);
		const postID = post._id;

		deletePostByID(postID, (response) => {
			if (response.ok === 1) {
				// go back to 1 page
				window.history.back();
				return;
			}
			toast.error("An error occurred. Please try again");

			setDeleteLoading(false);
		});
	}

	const [saved, setSaved] = useState(false);

	function save() {
		setSaved(true);
		bookmarkPost(
			{
				userID,
				postData: post,
				postType: "post",
			},
			(response) => {
				if (response.ok === 1) {
					setSaved(true);
					return;
				}
				setErrors("Something went wrong. Please try again.");
				setShowErrors(true);
			},
		);
	}

	const [canDelete, setCanDelete] = useState(false);

	useEffect(() => {
		if (post) {
			document.title = post.title;
			if (user.user.accessLevel === "super" || post.user.userID === user.user.userID) {
				setCanDelete(true);
			}
		}
	}, [post]);

	// const canDelete =
	// 	user.user.accessLevel === "super" | userID === post.user.userID ? true : false;

	return (
		<div className="mt-4">
			{showErrors && (
				<ErrorBar
					show={showErrors}
					onClose={() => {
						setErrors([]);
						setShowErrors(false);
					}}
					error={error}
				/>
			)}
			{isLoading ? (
				<div
					style={{
						height: "70vh",
					}}
				>
					<Loader />
				</div>
			) : (
				<>
					{!post ? (
						<NotFoundScreen />
					) : (
						<>
							<div className="rounded bg-white p-3 shadow-sm mb-2">
								<button onClick={() => window.history.back()} className="text-gray-500 mb-2">
									<i className="fa fa-arrow-left" />
								</button>
								<div className="flex justify-between">
									<h3>{post.title}</h3>
									{canDelete && (
										<button className="btn btn-sm button bg-danger" onClick={deletePost}>
											{deleteLoading ? (
												<div className="spinner-border" role="status">
													<span className="visually-hidden">Loading...</span>
												</div>
											) : (
												<>
													Delete <i className="fa-solid fa-trash" />
												</>
											)}
										</button>
									)}
								</div>
								<div className="mb-2 text-secondary">
									<p className="m-0">By Alumni {post.user.username.split(" ")[0]}</p>
									{dateFormat(post.createdAt)} at {timeFormat(post.createdAt)}
								</div>
								<span
									dangerouslySetInnerHTML={{
										__html: post.content,
									}}
									className="post-content"
								/>
								<div className="post-footer mb-3">
									<div className="icon-group" onClick={() => setShowCommentBox(!showCommentBox)}>
										<div className="icon comment">
											<i className="fa-solid fa-comment" />
										</div>
										<div className="count">{post.comments.length}</div>
									</div>
									<div className="icon-group">
										<div className={`icon save ${saved && "saved-post"}`} onClick={save}>
											<i className="fa-solid fa-bookmark" />
										</div>
									</div>
								</div>
							</div>
							<div className="rounded py-2 shadow-sm mb-3 bg-white">
								<textarea
									className="form-control comment-textarea mb-3"
									placeholder="Write a comment..."
									rows={7}
									onChange={handleCommentChange}
									value={comment}
								/>
								<div className="flex justify-end px-3">
									<button className="btn btn-sm send-button" onClick={handleCommentSubmit}>
										<i className="fa-solid fa-paper-plane" />
									</button>
								</div>
							</div>
							{post.comments.length > 0 ? (
								<div className="comments">
									{[...post.comments].reverse().map((comment) => (
										<div className="rounded bg-white p-3 shadow-sm mb-2" key={comment._id}>
											<div className="post-header">
												<p>By Alumni {comment.user.username.split(" ")[0]}</p>
												<div>
													<span className="timestamp">
														{dateFormat(comment.timestamp)} {" - "}
														{timeFormat(comment.timestamp)}
													</span>
												</div>
											</div>
											<div className="comment-content">
												<ReadMore>{comment.comment}</ReadMore>
											</div>
										</div>
									))}
								</div>
							) : (
								<div className="no-comments">No comments yet.</div>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
}

export default PostsScreen;
