import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Loader from "../../../components/Loader";
import { getUnaprovedPosts } from "../../api/posts";
import FeedPost from "../../components/FeedPost/FeedPost";

function UnapprovedPosts() {
	const [pendingPosts, setPendingPosts] = useState([]);
	const [page, setPage] = useState(1);
	const [isLoading, setIsLoading] = useState(true);

	function getPendingPosts() {
		getUnaprovedPosts(page, (response) => {
			setIsLoading(true);
			if (response.ok === 1) {
				setPendingPosts((prev) => {
					if (response.response.length === 0) {
						setPage(page);
						return prev;
					}
					setPage(page + 1);
					return [...prev, ...response.response];
				});
			} else {
				toast.error("An error occurred. Please try again");
			}
			setIsLoading(false);
		});
	}

	useEffect(() => {
		getPendingPosts();
	}, [page]);

	return (
		<div className="!mt-3 row">
			<div className="m-0 col-md-8">
				<h3 className="mb-2">Unapproved Posts</h3>
				{isLoading ? (
					<div className="h-[80vh]">
						<Loader />
					</div>
				) : (
					<>
						{pendingPosts.map((post, index) => (
							<FeedPost post={post} key={index} setPage={setPage} setPendingPosts={setPendingPosts} />
						))}
					</>
				)}
			</div>
		</div>
	);
}

export default UnapprovedPosts;
