// eslint-disable-next-line no-undef
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const EMPLOYMENT_SECTORS = [
	{
		code: "EDU",
		description: "Education",
	},
	{
		code: "FIN",
		description: "Finance",
	},
	{
		code: "HEALTH",
		description: "Healthcare and health services",
	},
	{
		code: "ADMIN",
		description: "Administration",
	},
	{
		code: "TOURISM",
		description: "Hospitality/Tourism",
	},
	{
		code: "SALES_MRKT",
		description: "Sales and marketing",
	},
	{
		code: "MEDIA",
		description: "Media and journalism",
	},
	{
		code: "AGRI",
		description: "Agriculture",
	},
	{
		code: "ACCOUNT",
		description: "Accounting, banking and finance",
	},
	{
		code: "INSURANCE",
		description: "Insurance and risk management",
	},
	{
		code: "LAW",
		description: "Legal services/Law",
	},
	{
		code: "ARCHI",
		description: "Architecture and Planning",
	},
	{
		code: "TECH",
		description: "Technology - ICT",
	},
	{
		code: "RETAIL",
		description: "Retail and commerce",
	},
	{
		code: "ARTS",
		description: "Arts and entertainment",
	},
	{
		code: "MANU",
		description: "Manufacturing/Industry",
	},
	{
		code: "ENG",
		description: "Engineering",
	},
	{
		code: "RESEARCH",
		description: "Research and consultancy",
	},
];

// GOV, PRIVATE, SELF, MNC, NGO, OTHER
export const EMPLOYER_TYPES = [
	{
		code: "GOV",
		description: "Government",
	},
	{
		code: "PRIVATE",
		description: "Private Sector",
	},
	{
		code: "SELF",
		description: "Self Employed",
	},
	{
		code: "MNC",
		description: "Multinational Corporation",
	},
	{
		code: "NGO",
		description: "Non-Governmental Organization",
	},
	{
		code: "OTHER",
		description: "Other",
	},
];

// FULL_TIME, PART_TIME, CONTRACT, INTERNSHIP, TEMPORARY, CONSULTANT, VOLUNTEER
export const EMPLOYMENT_TYPES = [
	{
		code: "FULL_TIME",
		description: "Full Time",
	},
	{
		code: "PART_TIME",
		description: "Part Time",
	},
	{
		code: "CONTRACT",
		description: "Contract",
	},
	{
		code: "INTERNSHIP",
		description: "Internship",
	},
	{
		code: "TEMPORARY",
		description: "Temporary",
	},
	{
		code: "CONSULTANT",
		description: "Consultant",
	},
	{
		code: "VOLUNTEER",
		description: "Volunteer",
	},
];

// LOOKING_FOR_WORK, OPEN_TO_MENTORING, LOOKING_FOR_MENTOR, HIRING
export const USER_STATUS = [
	{
		code: "LOOKING_FOR_WORK",
		description: "Looking for work",
	},
	{
		code: "OPEN_TO_MENTORING",
		description: "Open to mentoring",
	},
	{
		code: "LOOKING_FOR_MENTOR",
		description: "Looking for a mentor",
	},
	{
		code: "HIRING",
		description: "Hiring",
	},
];
