import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import AuthContext from "../../../auth/context";
import authStorage from "../../../auth/storage";

function DropDown() {
	const navigate = useNavigate();
	const { user } = useContext(AuthContext);
	const { slug } = user.user;

	function logOut() {
		authStorage.removeUser();
		navigate(0);
	}
	return (
		<div className="drop-down">
			<div className="drop-down-content">
				<ul>
					<Link to={"/u/" + slug}>
						<li>
							<span>Profile</span>
						</li>
					</Link>
					<Link to={"/change-password"}>
						<li>
							<span>Change Password</span>
						</li>
					</Link>
					<span onClick={logOut}>
						<li>
							<span>Sign out</span>
						</li>
					</span>
				</ul>
			</div>
		</div>
	);
}

export default DropDown;
