import React, { useState } from "react";
import { Link } from "react-router-dom";

import { dateFormat } from "../../handlers/functions";
import ReadMore from "../ReadMore/ReadMore";
import ErrorBar from "./../ErrorBar/";

function FeedPost({ post }) {
	// TODO: work on reactions. Like, comment, and save

	const [error] = useState("");
	const [showErrors, setShowErrors] = useState(false);

	return (
		<div className="rounded-md bg-white p-3 shadow-sm mb-4">
			<div className="post-header !mb-1">
				<div>
					<h4>{post.title}</h4>
					<div className="">
						<p className="m-0">
							By Alumni{" "}
							<Link className="user" to={"/u/" + post.user.slug}>
								{post.user.username.split(" ")[0]}
							</Link>{" "}
						</p>
						<p className="timestamp">{dateFormat(post.timestamp)}</p>
					</div>
				</div>
			</div>
			<ReadMore>{post.content}</ReadMore>

			<div className="flex items-center space-x-3 !mt-3">
				{post.comments && (
					<Link className="flex items-center *:leading-none text-gray-400" to={"/p/" + post.slug}>
						<i className="fa-solid fa-message !mr-2" />

						<div className="count text-gray-400">{post.comments.length} Comments</div>
					</Link>
				)}
			</div>
			{error && <ErrorBar error={error} show={showErrors} onClose={() => setShowErrors(!showErrors)} />}
		</div>
	);
}

export default FeedPost;
