import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import { checkIfSetupCompleted, handleLogin } from "../api/auth";
import logo from "../assets/images/BSJ Logos - Ocean.png";
import authStorage from "../auth/storage";
import FormInput from "../components/FormInput";
import { isValidEmail } from "../handlers/functions";

function LoginScreen() {
	useEffect(() => {
		checkIfSetupCompleted((response) => {
			if (!response.setup) {
				window.location.href = "/onboarding";
			}
		});
	}, []);

	const [authData, setAuthData] = useState({
		email: "",
		password: "",
		termsAndConditions: false,
	});

	const { email, password, termsAndConditions } = authData;

	const [loading, setLoading] = useState(false);

	const handleEmailChange = (event) => setAuthData({ ...authData, email: event.target.value });

	const handlePasswordChange = (event) => setAuthData({ ...authData, password: event.target.value });

	function handleSubmit(event) {
		event.preventDefault();

		if (authData.password.length === 0 || authData.email.length === 0) {
			toast.error("All fields are required");
			event.preventDefault();
			return;
		}
		if (!isValidEmail(authData.email)) {
			toast.error("Email Invalid");
			event.preventDefault();
			return;
		}
		if (password.length < 6) {
			toast.error("Password too short");
			event.preventDefault();
			return;
		}

		if (!termsAndConditions) {
			toast.error("You must agree to the terms and conditions");
			event.preventDefault();
			return;
		}

		setLoading(true);

		handleLogin(authData, (response) => {
			if (response.ok === 1) {
				const { token } = response;

				authStorage.storeUser(
					JSON.stringify({
						token,
					}),
				);
				setTimeout(() => {
					window.location.href = "/";
				}, 500);
				setLoading(false);
			} else {
				toast.error(response.errors);
				setLoading(false);
			}
		});
	}

	return (
		<>
			<Form>
				<div className="form-holder">
					<div className="hero-img">
						<img src={logo} alt="" />
					</div>
					<FormInput
						type="email"
						label="Email Address"
						placeholder="Enter your Email Address"
						value={email}
						onChange={handleEmailChange}
					/>
					<FormInput
						type="password"
						label="Password"
						placeholder="Enter your Password"
						value={password}
						onChange={handlePasswordChange}
					/>
					<div className="mb-3">
						<div className="form-check">
							<input
								className="form-check-input"
								type="checkbox"
								checked={termsAndConditions}
								onChange={() =>
									setAuthData({
										...authData,
										termsAndConditions: !termsAndConditions,
									})
								}
							/>
							<label className="form-check-label">
								I agree to the{" "}
								<Link to="/terms-and-conditions" target="_blank">
									Terms and Conditions
								</Link>{" "}
								and the{" "}
								<Link to="/community-guidelines" target="_blank">
									Community Guidelines
								</Link>
							</label>
						</div>
					</div>
					<button type="submit" className="btn btn-lg form-control bg-ocean" onClick={handleSubmit} disabled={loading}>
						{loading ? "Loading..." : "Login"}
					</button>
					<p>
						Forgot your password?{" "}
						<a href="mailto:alumni@schoolofstjude.co.tz" className="text-sky-500">
							Contact Us
						</a>
					</p>
					<p>
						Need to get registered? Email our team{" "}
						<a href="mailto:alumni@schoolofstjude.co.tz" className="text-sky-500">
							Reach out
						</a>
					</p>
				</div>
			</Form>
		</>
	);
}

export default LoginScreen;
