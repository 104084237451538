import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

import { cn } from "../../handlers/helpers";

function CustomLink({ children, to, ...props }) {
	let resolved = useResolvedPath(to);
	let match = useMatch({ path: resolved.pathname, end: true });

	return (
		<Link to={to} {...props}>
			<li
				className={cn("rounded-md hover:bg-sun hover:text-ocean !p-3 w-full", {
					"bg-sun text-ocean": match,
				})}
			>
				{children}
			</li>
		</Link>
	);
}

export default CustomLink;
