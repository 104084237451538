import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import emptyIcon from "../assets/images/empty.png";
import FeedPost from "../components/FeedPost/FeedPost";
import JobPost from "../components/JobPost";
import Loader from "../components/Loader";
import ReadMore from "../components/ReadMore";
import { useBookmarks } from "../queries/bookmarks.query";

function BookmarksScreen() {
	const [bookmarks, setBookmarks] = useState([]);

	const { data, isLoading } = useBookmarks();

	useEffect(() => {
		if (isLoading) return;
		if (data) {
			setBookmarks(data.bookmarks);
		}
	}, [data]);

	return (
		<div className="">
			{isLoading ? (
				<div
					style={{
						height: "70vh",
					}}
				>
					<Loader />
				</div>
			) : (
				<>
					{bookmarks.length === 0 ? (
						<div className="empty_list">
							<img src={emptyIcon} alt="" width="200" />
							<p>There&#39;s currently no saved Posts</p>
						</div>
					) : (
						<>
							{bookmarks.map((bookmark, index) => (
								<>
									{bookmark.postType === "scholarship" && (
										<div className="rounded bg-white p-3 shadow-sm mb-2 scholarship-post shadow-1" key={index}>
											<h5 className="scholarship-title">{bookmark.postData.title}</h5>
											{bookmark.postData.tags.length > 0 && (
												<div className="row tag-wrapper">
													<div className="col-auto">
														<label className="col-form-labe tag-label scholarship-tag-label">Tags:</label>
														{bookmark.postData.tags.map((tag, index) => (
															<span className="badge" key={index}>
																{tag}
															</span>
														))}
													</div>
												</div>
											)}
											<Link to={`/s/${bookmark.postData.slug}`} className="btn btn-sm button mt-3">
												Read More
											</Link>
										</div>
									)}
									{bookmark.postType === "job" && <JobPost key={index} job={bookmark.postData} />}
									{bookmark.postType === "post" && <FeedPost post={bookmark.postData} key={index} />}
									{bookmark.postType === "article" && (
										<div className="rounded bg-white p-3 shadow-sm mb-2" key={index}>
											<h3>{bookmark.postData.title}</h3>
											<p className="article-description">{bookmark.postData.description}</p>
											<ReadMore>{bookmark.postData.content}</ReadMore>
											<div className="post-footer">
												<div className="icon-group">
													<div className="count">Bookmark</div>
												</div>
											</div>
										</div>
									)}
								</>
							))}
						</>
					)}
				</>
			)}
		</div>
	);
}

export default BookmarksScreen;
