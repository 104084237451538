import React, { useState } from "react";
import toast from "react-hot-toast";

import { handleSetup } from "../api/auth";
import FormInput from "../components/FormInput";

function Onboarding() {
	const [data, setData] = useState({
		name: "",
		email: "",
		password: "",
		confirmPassword: "",
	});

	const [loading, setLoading] = useState(false);

	function handleChange(e) {
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	}

	function handleSubmit(e) {
		e.preventDefault();
		setLoading(true);

		// validation
		if (data.name.length === 0 || data.email.length === 0 || data.password.length === 0) {
			toast.error("All fields are required");
			setLoading(false);
			return;
		}

		if (data.password !== data.confirmPassword) {
			toast.error("Passwords do not match");
			setLoading(false);
			return;
		}

		const requestData = {
			name: data.name,
			email: data.email,
			password: data.password,
		};

		handleSetup(requestData, (response) => {
			setLoading(false);
			if (response.ok === 1) {
				toast.success("Setup completed, please login");
				return (window.location.href = "/login");
			}
			toast.error("An error occurred, please try again");
		});
	}

	return (
		<>
			<form>
				<div className="form-holder">
					<h2>Onboarding</h2>
					<h3 className="mb-2">Please setup your admin account</h3>

					<FormInput
						type="text"
						label="Full Name"
						placeholder="Enter your Full Name"
						value={data.name}
						onChange={handleChange}
						name="name"
					/>

					<FormInput
						type="email"
						label="Email Address"
						placeholder="Enter your Email Address"
						value={data.email}
						onChange={handleChange}
						name="email"
					/>

					<FormInput
						type="password"
						label="Password"
						placeholder="Enter your Password"
						value={data.password}
						onChange={handleChange}
						name="password"
					/>

					{data.password.length > 0 && (
						<FormInput
							type="password"
							label="Confirm Password"
							placeholder="Enter your Password"
							value={data.confirmPassword}
							onChange={handleChange}
							name="confirmPassword"
						/>
					)}

					<button type="submit" className="btn btn-lg form-control bg-ocean" onClick={handleSubmit} disabled={loading}>
						{loading ? "Loading..." : "Login"}
					</button>
				</div>
			</form>
		</>
	);
}

export default Onboarding;
