import "./index.css";
import "./assets/css/feed.css";
import "./assets/css/main.css";
import "./assets/css/modal.css";
import "./assets/css/scholarships.css";
import "./assets/css/messages.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { Toaster } from "react-hot-toast";

import App from "./App";

const container = document.getElementById("root");

const root = createRoot(container);

root.render(
	<React.StrictMode>
		<App />
		<Toaster position="bottom-right" reverseOrder={false} />
	</React.StrictMode>,
	document.getElementById("root"),
);
