import QueryString from "qs";

import Api from "../../config/api";

export async function getChatsByUserID(userID) {
	const { data } = await Api.get("/messages?userID=" + userID);
	console.log("data", data);
	return data;
}

export async function sendMessage(messageData) {
	const req = QueryString.stringify(messageData);

	const { data } = await Api.post("/messages", req);
	return data;
}
