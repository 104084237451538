import axios from "axios";
import QueryString from "qs";

import authStorage from "../../auth/storage";
import Api from "../../config/api";
import { API_BASE_URL } from "./../../config/constants";

const storedUser = authStorage.getUser();
// eslint-disable-next-line no-undef
const FRONTEND_API_KEY = process.env.REACT_APP_FRONTEND_API_KEY;

export async function fetchUserProfile(slug) {
	const { data } = await Api.get("/user/" + slug);
	return data;
}

export function addEducationHistory(education, callback) {
	const data = QueryString.stringify(education);

	const config = {
		method: "post",
		url: API_BASE_URL + "/user/education",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function editEducationHistory(educationData, callback) {
	const data = QueryString.stringify(educationData);

	const config = {
		method: "patch",
		url: API_BASE_URL + "/user/education",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			"X-API-Key": FRONTEND_API_KEY,
			Authorization: "Bearer " + storedUser.token,
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function deleteEducationItem({ userId, referenceId }, callback) {
	const data = QueryString.stringify({
		userId,
		referenceId,
	});
	const config = {
		method: "delete",
		url: API_BASE_URL + "/user/education",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function addWorkHistory(work, callback) {
	const data = QueryString.stringify(work);

	const config = {
		method: "post",
		url: API_BASE_URL + "/user/work-history",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function editWorkHistory({ userId, place, position, startDate, endDate, referenceId }, callback) {
	const workData = {
		userId,
		place,
		position,
		startDate,
		endDate,
		referenceId,
	};

	var data = QueryString.stringify(workData);

	var config = {
		method: "patch",
		url: API_BASE_URL + "/user/work-history",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function deleteWorkItem({ userId, referenceId }, callback) {
	const data = QueryString.stringify({
		userId: userId,
		referenceId: referenceId,
	});
	const config = {
		method: "delete",
		url: API_BASE_URL + "/user/work-history",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function searchUsersByName(query, callback) {
	const config = {
		method: "get",
		url: API_BASE_URL + "/search/" + query,
		headers: {
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function updateBio(data, callback) {
	const bioData = QueryString.stringify(data);
	const config = {
		method: "patch",
		url: API_BASE_URL + "/user/bio",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: bioData,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function addLinkedInLink(profileData, callback) {
	const data = QueryString.stringify(profileData);
	const config = {
		method: "patch",
		url: API_BASE_URL + "/user/linkedIn",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function updateProfileImage({ userID, image }, callback) {
	var data = new FormData();
	data.append("image", image);
	data.append("userID", userID);

	const config = {
		method: "patch",
		url: API_BASE_URL + "/user/profile-picture",
		headers: {
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function bookmarkPost({ userID, postData, postType }, callback) {
	let data = QueryString.stringify({
		userID,
		postData,
		postType,
	});
	let config = {
		method: "patch",
		url: API_BASE_URL + "/bookmarks",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios(config)
		.then((response) => {
			callback(response.data);
		})
		.catch((error) => {
			console.log(error);
		});
}

export async function getBookmarks() {
	const { data } = await Api.get("/bookmarks");
	return data;
}

export async function deleteUser(userID) {
	const { data } = await Api.delete("/user/delete/", { userID });
	window.location.reload();
	return data;
}

export async function reinviteUser(userID) {
	const { data } = await Api.post("/user/invite/", { userID });
	return data;
}

export function addProfessionalCertification(value, callback) {
	// const data = { field: "", email: "", year: "", certificate: "", }
	let data = QueryString.stringify({ ...value });

	let config = {
		method: "post",
		url: API_BASE_URL + "/user/certifications",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			callback(response.data);
		})
		.catch((error) => {
			console.log(error);
		});
}

export function editProfessionalCertification(value, callback) {
	// const type = {  userId, referenceId, certificate, year, field }
	let data = QueryString.stringify({ ...value });

	let config = {
		method: "patch",
		url: API_BASE_URL + "/user/certifications",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			callback(response.data);
		})
		.catch((error) => {
			console.log(error);
		});
}

export function deleteProfessionalCertification(value, callback) {
	// const type = {  'referenceId': '', 'userId': '' }
	let data = QueryString.stringify({
		...value,
	});

	let config = {
		method: "delete",
		url: API_BASE_URL + "/user/certifications",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios
		.request(config)
		.then((response) => {
			callback(response.data);
		})
		.catch((error) => {
			console.log(error);
		});
}
