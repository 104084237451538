import React from "react";
import { useEffect, useState } from "react";
import { decodeToken, isExpired } from "react-jwt";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import AuthContext from "./auth/context";
import authStorage from "./auth/storage";
import AdminNavigator from "./navigator/AdminNavigator";
import AppNavigator from "./navigator/AppNavigator";
import AuthNavigator from "./navigator/AuthNavigator";
import Feedback from "./navigator/components/Feedback";

function App() {
	const [user, setUser] = useState(null);

	const userData = authStorage.getUser();

	function restoreUser() {
		if (!userData) return;

		const decodedToken = decodeToken(userData?.token);
		const isTokenExpired = isExpired(userData?.token);

		if (isTokenExpired) {
			authStorage.removeUser();
			return;
		}

		setUser({ user: decodedToken });
	}

	useEffect(() => {
		if (!user) {
			restoreUser();
		}
	}, [user]);

	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<AuthContext.Provider value={{ user, setUser }}>
				<>
					{user ? (
						<>
							{user.user.accessLevel === "student" && <AppNavigator />}
							{(user.user.accessLevel === "super") | (user.user.accessLevel === "admin") ? <AdminNavigator /> : null}
							<Feedback />
						</>
					) : (
						<AuthNavigator />
					)}
				</>
				<ReactQueryDevtools initialIsOpen={false} />
			</AuthContext.Provider>
		</QueryClientProvider>
	);
}

export default App;
