import React from "react";
import { Toast, ToastContainer } from "react-bootstrap";

function ErrorBar({ show, onClose, error }) {
	return (
		<ToastContainer position="top-end" className="p-3">
			<Toast autohide={true} show={show} onClose={onClose} className="error d-flex">
				<Toast.Body>{error}</Toast.Body>
				<Toast.Header className="error" />
			</Toast>
		</ToastContainer>
	);
}

export default ErrorBar;
