import QueryString from "qs";

import Api from "../../config/api";

export async function getAllJobs(page) {
	const { data } = await Api.get("/jobs?page=" + page);
	return data;
}

export async function getJobBySlug(slug) {
	const { data } = await Api.get(`/jobs/${slug}`);
	return data;
}

export async function postAJob(jobData) {
	const { title, company, location, description, url, userID, applicationDeadline, type } = jobData;

	const reqData = QueryString.stringify({
		title,
		company,
		location,
		type,
		description,
		url,
		userID,
		applicationDeadline,
	});

	const { data } = await Api.post("/jobs", reqData);
	return data;
}
