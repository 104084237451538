import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { searchUsersByName } from "../api/user";
import Loader from "../components/Loader";

function SearchScreen() {
	const [searchQuery, setSearchQuery] = useState("");

	const { state } = useLocation();

	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(false);
	const getUsers = async () => {
		setLoading(true);
		searchUsersByName(searchQuery, (response) => {
			if (response.ok === 1) {
				setUsers([...response.users]);
				return setLoading(false);
			}
			return setLoading(false);
		});
	};
	useEffect(() => {
		setSearchQuery(state.query);
	}, [state]);

	useEffect(() => {
		if (searchQuery.length > 0) {
			getUsers();
		}
	}, [searchQuery]);

	useEffect(() => {}, [users]);

	return (
		<div className="">
			<h3>Search</h3>
			{!loading ? (
				<div className="users">
					{users.map((user) => (
						<Link key={user._id} to={`/u/${user.slug}`}>
							<div className="!shadow bg-white !mb-3 rounded !p-3">
								<div className="user__info">
									<p className="font-semibold">
										{user.name}{" "}
										<span>{user.accessLevel === "super" && <span className="badge text-bg-warning">Admin</span>}</span>
									</p>
									<p className="leading-none text-sm">
										{user.accessLevel !== "super" && <p>Class of {user.yearOfGraduation}</p>}
									</p>
								</div>
							</div>
						</Link>
					))}
				</div>
			) : (
				<Loader />
			)}
		</div>
	);
}
export default SearchScreen;
