const key = "user";

function storeUser(user) {
	try {
		localStorage.setItem(key, user);
	} catch (error) {
		console.log(error);
	}
}

function getUser() {
	try {
		const value = localStorage.getItem(key);
		if (value) {
			return JSON.parse(value);
		}
	} catch (error) {
		console.log(error);
	}
}

function removeUser() {
	try {
		localStorage.removeItem(key);
	} catch (error) {
		console.log(error);
	}
}

const authStorage = { storeUser, getUser, removeUser };
export default authStorage;
