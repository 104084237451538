/* eslint-disable no-useless-escape */

import dayjs from "dayjs";

import badWords from "../config/badWords";

export function isValidEmail(email) {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

export function dateFormat(date) {
	const today = dayjs();
	if (dayjs(date).isAfter(today)) {
		if (dayjs(date).isSame(today)) {
			return "Today";
		}
		if (dayjs(date).isSame(today.add(1, "days"))) {
			return "Tomorrow";
		}
		if (dayjs(date).isAfter(today.add(1, "days")) && dayjs(date).isBefore(today.add(1, "week"))) {
			const days = dayjs(date).diff(today, "days") + 1;
			return `${days} days to go`;
		}
	}
	return dayjs(date).format("D MMM, YYYY");
}
export function timeFormat(date) {
	return dayjs(date).format("HH:mm");
}
export function dateFormatCompute(date) {
	return dayjs(date).format("y-MM-DD");
}
export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
export function isValidURL(url) {
	const pattern = new RegExp(
		"^(https?:\\/\\/)?" + // protocol
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
			"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
			"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
			"(\\#[-a-z\\d_]*)?$",
		"i",
	); // fragment locator
	return !!pattern.test(url);
}
export function removeIMG(str) {
	return str.replace(/<img[^>]+>/gi, "");
}

export function filterBadWords(str) {
	badWords.forEach((word) => {
		// replace word with asterisks of same length
		str = str.replace(new RegExp(word, "gi"), "*".repeat(word.length));
	});
	return str;
}

export function getPaginatedResults(data) {
	const results = [];
	data.pages.forEach((page) => {
		return results.push(...page.response);
	});
	return results;
}
