import { useQuery } from "react-query";

import { getBookmarks } from "../api/user";

const bookmarkKeys = { all: ["bookmarks"] };

export function useBookmarks(page = 1, options = {}) {
	return useQuery({
		queryKey: bookmarkKeys.all,
		queryFn: () => getBookmarks(page),
		...options,
	});
}
