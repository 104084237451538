import React from "react";

function CommunityGuidelines() {
	return (
		<div className="container">
			<div className="card mt-3">
				<div className="card-body p-3">
					<h1 className="card-title">St Jude&#39;s Connect Community Guidelines</h1>
					<h3>Objectives</h3>
					<p>
						The objectives of St Jude&#39;s Connect are to support alumni of The School of St Jude (“the School”) in
						their personal and professional endeavors. We aim to foster lasting connections between our Alumni to
						establish strong and mutually beneficial relationships to promote professional development.{" "}
					</p>
					<p>
						We want St Jude&#39;s Connect to be an authentic and safe place for nurturing healthy professional
						relationships, generate opportunities for Alumni and facilitate continued engagement with the school. Help
						us foster this community.
					</p>
					<h3>Terms of Use/Rules of conduct:</h3>
					<p>
						St Jude&#39;s Connect embodies our core values of honesty, kindness, respect, and responsibility. However,
						we also recognize and celebrate the diversity among our members, including differences in culture, age,
						beliefs, and opportunities.
					</p>
					<h3>Do:</h3>
					<ul>
						<li>Treat others online as you would treat them in real life</li>
						<li>Be tolerant towards other&#39;s viewpoints; respectfully disagree when opinions do not align.</li>
						<li>Respect the privacy and personal information of other alumni.</li>
						<li>Communicate with courtesy and respect.</li>
						<li>Share only contents that have been authorized by the admin.</li>
						<li>Foster meaningful and genuine interactions.</li>
						<li>Always behave in a way that reflects well on the School.</li>
						<li>
							Act consistently in the best interests of the School, as ambassadors and advocates for St Jude&#39;s.
						</li>
					</ul>
					<p>
						The features within the portal are intended to facilitate communication, networking, professional
						development, alumni spirit, and to promote common interests.
					</p>
					<p>Some examples might include:</p>
					<ul>
						<li>
							Helping to coordinate our annual reunions and/or other school events such as Student Scholarship Process,
							St Jude&#39;s Day and graduations.
						</li>
						<li>Reaching out to individual alumni for professional advice, to reconnect, or to join a community.</li>
						<li>Requesting mentorship from another Alumni.</li>
						<li>Providing career advice or information about potential professional opportunities.</li>
					</ul>
					<h3>Do not:</h3>
					<ul>
						<li>
							Make personal attacks or defamatory statements against others, trolling, bullying, or making inflammatory
							comments to other community members
						</li>
						<li>
							Post profanity or share explicit material either in text, photo, video or audio of yourself or any other
							person
						</li>
						<li>Distribute misinformation, spam or rumors</li>
						<li>
							Participate or encourage racism, sexism and any other discrimination or engage in any form of illegal
							activities
						</li>
						<li>
							Use any personal information made available in St Jude&#39;s Connect such as names, social media accounts,
							employment information for any commercial, advocacy, personal fundraising, promotional, political or
							religious purpose.
						</li>
						<li>Participate in or encourage any actions that could harm the School or damage its reputation</li>
						<li>
							Provide false information or impersonate another individual on St Jude&#39;s Connect. We take the
							integrity of our community seriously and will not tolerate any attempts to deceive or misrepresent
							oneself.
						</li>
					</ul>
					<h3>Liability:</h3>
					<ul>
						<li>The School bears no responsibility for the financing or finances of community groups.</li>
						<li>
							The School bears no responsibility for any legal disputes arising from the individual use of the platform
							by the Alumni
						</li>
						<li>
							The views shared on the platform by alumni are those of the individual alumni and do not reflect the views
							of the school? (something like that)
						</li>
					</ul>
					<h3>Consequences:</h3>
					<p>
						We developed the Community Guidelines to support and safeguard our community. By using St Jude&#39;s
						Connect, you are committing to adhere to these guidelines and our privacy policies, which are legally
						binding. Failure to comply with these guidelines and policies may result in removal from the portal,
						disciplinary action by the School, or referral to legal authorities.
					</p>
					<p>
						All alumni members have the responsibility to report behavior that violates our guidelines and privacy
						policies by using the report functions within the platform or reporting directly to
						<a href="mailto:alumni@schoolofstjude.co.tz">alumni@schoolofstjude.co.tz</a>
					</p>
					<h3>Agreement:</h3>
					<p>
						By accessing The St Jude&#39;s Connect and activating your profile, you are deemed to have accepted the
						terms and conditions outlined above. Thank you for your support in building one of the premier alumni
						communities in the country.
					</p>
					<p>The Alumni Team/The School of St Jude</p>
				</div>
			</div>
		</div>
	);
}

export default CommunityGuidelines;
