import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { getChatsByUserID } from "../../../api/messages";
import AuthContext from "../../../auth/context";
import DropDown from "./DropDown";

function TopNav({ sidenav }) {
	const { user } = useContext(AuthContext);
	const { userID } = user.user;

	const [showDropDown, setShowDropDown] = useState(false);
	const [sidenavOpen, setSidenavOpen] = useState(false);
	function toggleSidebar() {
		setSidenavOpen(!sidenavOpen);
		sidenav.setTranslate((prev) => (prev === "translateX(-300px)" ? "translateX(0px)" : "translateX(-300px)"));
	}

	const [pathname, setPathname] = useState(window.location.pathname);

	useEffect(() => {
		setPathname(window.location.pathname);
	}, [window.location.pathname]);

	useEffect(() => {
		if (sidenav.screenWidth < 800) {
			sidenav.setTranslate("translateX(-300px)");
			setSidenavOpen(false);
		}
	}, [pathname]);

	const [searchQuery, setSearchQuery] = useState("");
	function handleChange(e) {
		setSearchQuery(e.target.value);
	}
	const navigate = useNavigate();

	const [chats, setChats] = useState([]);
	const [unreadChats, setUnreadChats] = useState(0);

	function fetchChats() {
		getChatsByUserID(userID, (response) => {
			if (response.ok === 1) {
				const chats = response.chats;
				return setChats([...chats]);
			}
			return console.log(response);
		});
	}

	function getNewMessages() {
		const unreadCount = chats.filter((chat) => {
			return chat.changedBy !== userID && !chat.seen;
		});
		return setUnreadChats(unreadCount.length);
	}
	useEffect(() => {
		getNewMessages();
	}, [chats]);

	useEffect(() => {
		setUnreadChats(0);
		fetchChats();
		setInterval(() => {
			fetchChats();
		}, 15000);
	}, []);

	return (
		<nav className="flex w-full justify-between bg-white items-center !shadow rounded !p-3">
			<div className="flex space-x-2 items-center">
				{sidenav.screenWidth < 768 && (
					<div className="hamburger">
						<input type="checkbox" className="openSidebarMenu hidden" id="openSidebarMenu" onChange={toggleSidebar} />
						<label htmlFor="openSidebarMenu" className="sidebarIconToggle">
							<div className="sidenav-icon">
								{sidenavOpen ? <i className="fa-solid fa-x" /> : <i className="fa-solid fa-bars" />}
							</div>
						</label>
					</div>
				)}
				<form
					className="bg-gray-100 flex items-center w-42 md:w-80 !px-2 rounded-md shadow-sm"
					onSubmit={(e) => {
						e.preventDefault();

						if (searchQuery.length > 0) {
							return navigate("search", {
								state: { query: searchQuery },
								replace: true,
							});
						}
					}}
				>
					<input
						type="text"
						placeholder="Search"
						value={searchQuery}
						className="border-0 rounded bg-transparent w-full m-0 focus:outline-0 focus:border-0"
						onChange={handleChange}
					/>
					<span onClick={() => setSearchQuery("")} className="clear">
						{searchQuery.length > 0 ? <>&times;</> : <i className="fa-solid fa-magnifying-glass" />}
					</span>
				</form>
			</div>
			<ul className="flex space-x-5 px-2">
				<Link to="messages" className="relative">
					<li>
						<i className="fa-solid fa-comments" />
					</li>
					{unreadChats > 0 && (
						<span className="absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white">
							{unreadChats}
							<span className="visually-hidden">unread messages</span>
						</span>
					)}
				</Link>
				{/* <li>
					<i className="fa-solid fa-bell"/>
				</li> */}
				<li className="relative" onClick={() => setShowDropDown((prev) => !prev)}>
					<div className="profile-img">
						<i className="fa-solid fa-user" />
					</div>
					{showDropDown && <DropDown />}
				</li>
			</ul>
		</nav>
	);
}

export default TopNav;
