import React, { Suspense, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Loader from "../components/Loader";
import { cn } from "../handlers/helpers";
import BookmarksScreen from "../screens/BookmarksScreen";
import EventScreen from "../screens/EventScreen";
import JobScreen from "../screens/JobScreen";
import PostsScreen from "../screens/PostsScreen";
import ScholarshipScreen from "../screens/ScholarshipScreen";
import SearchScreen from "../screens/SearchScreen";

const ArticlesScreen = React.lazy(() => import("../screens/ArticlesScreen"));
const MessagesScreen = React.lazy(() => import("../admin/screens/MessagesScreen/MessagesScreen"));

const ChangePasswordScreen = React.lazy(() => import("../screens/ChangePasswordScreen"));
const NotFoundScreen = React.lazy(() => import("../screens/NotFoundScreen"));
const EventsScreen = React.lazy(() => import("../screens/EventsScreen"));
const JobsScreen = React.lazy(() => import("../screens/JobsScreen"));

const SideNav = React.lazy(() => import("../components/SideNav"));
const HomeScreen = React.lazy(() => import("../screens/HomeScreen"));
const CommunitiesScreen = React.lazy(() => import("../screens/CommunitiesScreen"));
const CommunityPage = React.lazy(() => import("../views/CommunityPage"));
const TopNav = React.lazy(() => import("../components/TopNav"));

const ScholarshipsScreen = React.lazy(() => import("../screens/ScholarshipsScreen"));
const ProfileScreen = React.lazy(() => import("../screens/ProfileScreen"));

function SideNavLayout({ screenWidth, translate, collapseSidenav, setCollapseSidenav, setTranslate, children }) {
	const isMobile = useMemo(() => screenWidth < 768);
	return (
		<main className="">
			<SideNav
				sidenav={{
					screenWidth,
					translate,
					collapseSidenav,
					setCollapseSidenav,
					setTranslate,
				}}
			/>
			<div
				className={cn("section !p-3 !my-3 rounded-lg bg-sand shadow-inner !mr-3 min-h-[calc(100vh-24px)]", {
					"ml-[300px]": collapseSidenav || !isMobile,
					"!ml-2 !mr-2": isMobile,
				})}
			>
				{children}
			</div>
		</main>
	);
}

function AppNavigator() {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	function detectWidth() {
		setScreenWidth(window.innerWidth);
	}

	const [collapseSidenav, setCollapseSidenav] = useState(false);

	const [translate, setTranslate] = useState("translateX(0px)");

	useEffect(() => {
		setTranslate(screenWidth < 800 ? "translateX(-300px)" : "translateX(0px)");
		window.addEventListener("resize", detectWidth);
		return () => {
			window.removeEventListener("resize", detectWidth);
		};
	}, [screenWidth]);
	return (
		<Suspense
			fallback={
				<div className="h-[80vh]">
					<Loader />
				</div>
			}
		>
			<BrowserRouter>
				<SideNavLayout
					collapseSidenav={collapseSidenav}
					setCollapseSidenav={setCollapseSidenav}
					screenWidth={screenWidth}
					setTranslate={setTranslate}
					translate={translate}
				>
					<TopNav
						sidenav={{
							screenWidth,
							translate,
							collapseSidenav,
							setCollapseSidenav,
							setTranslate,
						}}
					/>
					<div className="">
						<Routes>
							<Route path="/" element={<HomeScreen />} />
							<Route path="communities" element={<CommunitiesScreen />} />
							<Route path="c/:slug" element={<CommunityPage />} />
							<Route path="u/:slug" element={<ProfileScreen />} />
							<Route path="p/:slug" element={<PostsScreen />} />
							<Route path="e/:slug" element={<EventScreen />} />
							<Route path="s/:slug" element={<ScholarshipScreen />} />
							<Route path="j/:slug" element={<JobScreen />} />
							<Route path="jobs" element={<JobsScreen />} />
							<Route path="scholarships" element={<ScholarshipsScreen />} />
							<Route path="events" element={<EventsScreen />} />
							<Route path="articles-newsletters" element={<ArticlesScreen />} />
							<Route path="change-password" element={<ChangePasswordScreen />} />
							<Route path="/bookmarks" element={<BookmarksScreen />} />
							<Route path="messages" element={<MessagesScreen />} />
							<Route path="search" element={<SearchScreen />} />
							<Route path="*" element={<NotFoundScreen />} />
						</Routes>
					</div>
				</SideNavLayout>
			</BrowserRouter>
		</Suspense>
	);
}

export default AppNavigator;
