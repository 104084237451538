import Api from "../../config/api";

export async function fetchPendingJobs(page) {
	const { data } = await Api.get("/jobs/pending?page=" + page);
	return data;
}

export async function deleteJobByID(id) {
	const { data } = await Api.delete(`/jobs?id=${id}`);
	return data;
}

export async function approveJobByID(id) {
	const { data } = await Api.patch(`/jobs?id=${id}`);
	return data;
}
