import React from "react";
import { Link } from "react-router-dom";

import logo from "../../../assets/images/favicon.png";
import CustomLink from "./../../../components/CustomLink/CustomLink";

function SideNav({ sidenav }) {
	function toggleSidebar() {
		sidenav.setTranslate((prev) => (prev === "translateX(-300px)" ? "translateX(0px)" : "translateX(-300px)"));
	}
	return (
		<div>
			<nav
				className="h-screen fixed left-0 top-0 w-[300px] bg-ocean-500 text-white z-10"
				style={{
					transform: sidenav.translate,
					WebkitTransform: sidenav.translate,
					msTransform: sidenav.translate,
					transition: "transform 0.3s",
				}}
			>
				<ul className="flex flex-col justify-between !px-5 py-2 space-y-2">
					{sidenav.screenWidth < 768 && (
						<div className="hamburger">
							<input type="checkbox" className="openSidebarMenu hidden" id="openSidebarMenu" onChange={toggleSidebar} />
							<label htmlFor="openSidebarMenu" className="sidebarIconToggle">
								<div className="sidenav-icon">
									<i className="fa-solid fa-x" />
								</div>
							</label>
						</div>
					)}

					<Link to="/" className="group">
						<div className="flex gap-2 pl-8 items-center my-4">
							<img src={logo} className="h-12" alt="logo" />

							<h4 className="text-2xl leading-none">{"St Jude's Connect"}</h4>
						</div>
					</Link>

					<CustomLink to="/">
						<i className="!mr-3 fa-solid fa-house" />
						<span>Home</span>
					</CustomLink>
					<CustomLink to="/posts">
						<i className="!mr-3 fa-solid fa-clock" />
						<span>Unapproved Posts</span>
					</CustomLink>
					<CustomLink to="/communities">
						<i className="!mr-3 fa-solid fa-users" />
						<span>Communities</span>
					</CustomLink>
					<CustomLink to="/articles-newsletters">
						<i className="!mr-3 fa-solid fa-newspaper" />
						<span>Articles & Newsletters</span>
					</CustomLink>
					<CustomLink to="/scholarships">
						<i className="!mr-3 fa-solid fa-graduation-cap" />
						<span>Scholarship Posts</span>
					</CustomLink>
					<CustomLink to="/jobs">
						<i className="!mr-3 fa-solid fa-briefcase" />
						<span>Job Posts</span>
					</CustomLink>
					<CustomLink to="/events">
						<i className="!mr-3 fa-solid fa-calendar-days" />
						<span>Event Posts</span>
					</CustomLink>
					<CustomLink to="/accounts">
						<i className="!mr-3 fa-solid fa-user" />
						<span>Accounts</span>
					</CustomLink>
				</ul>
			</nav>
		</div>
	);
}

export default SideNav;
