import Axios from "axios";
import QueryString from "qs";

import authStorage from "../../auth/storage";
import Api, { FRONTEND_API_KEY } from "../../config/api";
import { API_BASE_URL } from "./../../config/constants";

const storedUser = authStorage.getUser();

export async function postScholarship(scholarshipData) {
	const reqData = QueryString.stringify(scholarshipData);

	const { data } = await Api.post("/scholarships", reqData);
	return data;
}

export async function getScholarships(page = 1) {
	const { data } = await Api.get("/scholarships?page=" + page);
	return data;
}

export function getScholarshipBySlug(slug, callback) {
	var config = {
		method: "get",
		url: API_BASE_URL + "/scholarships/" + slug,
		headers: {
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: {},
	};

	Axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}
