import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import { getScholarshipBySlug } from "../api/scholarships";
import { bookmarkPost } from "../api/user";
import AuthContext from "../auth/context";
import Loader from "../components/Loader";
import NotFoundScreen from "./NotFoundScreen";

function ScholarshipScreen() {
	const { slug } = useParams();

	const [scholarship, setScholarship] = useState(null);
	const [loading, setLoading] = useState(true);
	const [saved, setSaved] = useState(false);

	const { user } = useContext(AuthContext);
	const { userID } = user.user;

	function bookmark() {
		setSaved(true);
		bookmarkPost(
			{
				userID,
				postData: scholarship,
				postType: "scholarship",
			},
			(response) => {
				if (response.ok === 1) {
					setSaved(true);
					return;
				}
				toast.error("Something went wrong. Please try again.");
			},
		);
	}

	function fetchScholarship() {
		getScholarshipBySlug(slug, (response) => {
			if (response.ok === 1) {
				setLoading(false);
				setScholarship(response.response[0]);
			} else {
				toast.error("Something went wrong. Please try again.");
				setLoading(false);
			}
		});
	}

	useEffect(() => {
		fetchScholarship();
	}, [slug]);

	useEffect(() => {
		if (scholarship) {
			document.title = scholarship.title;
		}
	}, [scholarship]);

	return (
		<div className="">
			{loading ? (
				<div className="h-[80vh]">
					<Loader />
				</div>
			) : (
				<div>
					{scholarship ? (
						<div className="rounded bg-white p-3 shadow-sm mb-2">
							<h4 className="scholarship-title">{scholarship.title}</h4>
							{scholarship.tags.length > 0 && (
								<div className="row tag-wrapper">
									<div className="col-auto">
										<span className="col-form-labe tag-label scholarship-tag-label">Tags:</span>
										{scholarship.tags.map((tag) => (
											<span className="badge" key={tag}>
												{tag}
											</span>
										))}
									</div>
								</div>
							)}
							<span
								// eslint-disable-next-line react/no-danger
								dangerouslySetInnerHTML={{
									__html: scholarship.description,
								}}
							/>
							<div className="post-footer">
								<div className="icon-group">
									<div className="count">Bookmark</div>
									<button className={`icon save ${saved && "saved-post"}`} onClick={bookmark} type="button">
										<i className="fa-solid fa-bookmark" />
									</button>
								</div>
							</div>
						</div>
					) : (
						<NotFoundScreen />
					)}
				</div>
			)}
		</div>
	);
}

export default ScholarshipScreen;
