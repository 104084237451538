import axios from "axios";
import QueryString from "qs";

import authStorage from "../../auth/storage";
import Api, { FRONTEND_API_KEY } from "../../config/api";
import { API_BASE_URL } from "./../../config/constants";

const storedUser = authStorage.getUser();

export async function makePost(postData) {
	const requestData = QueryString.stringify(postData);

	const { data } = await Api.post("/posts", requestData);
	return data;
}

export function getPostsByUserID(userID, page, callback) {
	const config = {
		method: "get",
		url: API_BASE_URL + "/posts?userID=" + userID + "&page=" + page,
		headers: {
			"X-API-Key": FRONTEND_API_KEY,
			Authorization: "Bearer " + storedUser.token,
		},
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export async function getPostsByCommunityID(comminityID, page) {
	const { data } = await Api.get("/posts/" + comminityID + "?page=" + page);
	return data;
}

export async function getHomepagePosts(page) {
	const { data } = await Api.get("/posts/home?page=" + page);
	return data;
}

export function getPostsBySlug(slug, callback) {
	var config = {
		method: "get",
		url: API_BASE_URL + "/p/" + slug,
		headers: {
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function commentOnPost(postID, commentData, callback) {
	var data = QueryString.stringify(commentData);
	var config = {
		method: "post",
		url: API_BASE_URL + "/comments/" + postID,
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			"X-API-Key": FRONTEND_API_KEY,
			Authorization: "Bearer " + storedUser.token,
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}
