import React, { Suspense, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import SideNav from "../admin/components/SideNav";
import TopNav from "../admin/components/TopNav";
import UnapprovedPosts from "../admin/screens/UnapprovedPosts";
import { cn } from "../handlers/helpers";
import PostsScreen from "../screens/PostsScreen";
import SearchScreen from "../screens/SearchScreen";

const JobsScreen = React.lazy(() => import("../admin/screens/JobsScreen"));
const ArticlesScreen = React.lazy(() => import("../admin/screens/ArticlesScreen"));
const ScholarshipsScreen = React.lazy(() => import("../admin/screens/ScholarshipsScreen/ScholarshipsScreen"));
const AccountsScreen = React.lazy(() => import("../admin/screens/AccountsScreen"));
const NotFoundScreen = React.lazy(() => import("../screens/NotFoundScreen"));
const ProfileScreen = React.lazy(() => import("../screens/ProfileScreen"));
const CommunityPage = React.lazy(() => import("../views/CommunityPage"));
const EventsScreen = React.lazy(() => import("../admin/screens/EventsScreen"));
const HomeScreen = React.lazy(() => import("../admin/screens/HomeScreen"));
const ChangePasswordScreen = React.lazy(() => import("../screens/ChangePasswordScreen"));
const CommunitiesScreen = React.lazy(() => import("../admin/screens/CommunitiesScreen"));
const MessagesScreen = React.lazy(() => import("../admin/screens/MessagesScreen"));

function SideNavLayout({ screenWidth, translate, collapseSidenav, setCollapseSidenav, setTranslate, children }) {
	const isMobile = useMemo(() => screenWidth < 768);
	return (
		<main className="">
			<SideNav
				sidenav={{
					screenWidth,
					translate,
					collapseSidenav,
					setCollapseSidenav,
					setTranslate,
				}}
			/>
			<div
				className={cn("section !p-3 !my-3 rounded-lg bg-sand shadow-inner !mr-3 min-h-[calc(100vh-24px)]", {
					"ml-[300px]": collapseSidenav || !isMobile,
					"!ml-2 !mr-2": isMobile,
				})}
			>
				{children}
			</div>
		</main>
	);
}

function AdminNavigator() {
	document.title = "Admin";

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	function detectWidth() {
		setScreenWidth(window.innerWidth);
	}

	const [collapseSidenav, setCollapseSidenav] = useState(false);

	const [translate, setTranslate] = useState("translateX(0px)");

	useEffect(() => {
		setTranslate(screenWidth < 800 ? "translateX(-300px)" : "translateX(0px)");
		window.addEventListener("resize", detectWidth);
		return () => {
			window.removeEventListener("resize", detectWidth);
		};
	}, [screenWidth]);

	return (
		<Suspense
			fallback={
				<div className="content">
					<div className="spinner-border text-warning" role="status">
						<span className="visually-hidden">Loading...</span>
					</div>
				</div>
			}
		>
			<BrowserRouter>
				<SideNavLayout
					collapseSidenav={collapseSidenav}
					setCollapseSidenav={setCollapseSidenav}
					screenWidth={screenWidth}
					setTranslate={setTranslate}
					translate={translate}
				>
					<TopNav
						sidenav={{
							screenWidth,
							translate,
							collapseSidenav,
							setCollapseSidenav,
							setTranslate,
						}}
					/>
					<div className="">
						<Routes>
							<Route path="/communities" element={<CommunitiesScreen />} />
							<Route path="/articles-newsletters" element={<ArticlesScreen />} />
							<Route path="/scholarships" element={<ScholarshipsScreen />} />
							<Route path="/jobs" element={<JobsScreen />} />
							<Route path="/events" element={<EventsScreen />} />
							<Route path="/accounts" element={<AccountsScreen />} />
							<Route path="c/:slug" element={<CommunityPage />} />
							<Route path="p/:slug" element={<PostsScreen />} />
							<Route path="u/:slug" element={<ProfileScreen />} />
							<Route path="change-password" element={<ChangePasswordScreen />} />
							<Route path="messages" element={<MessagesScreen />} />
							<Route path="search" element={<SearchScreen />} />
							<Route path="/" element={<HomeScreen />} />
							<Route path="/posts" element={<UnapprovedPosts />} />
							<Route path="*" element={<NotFoundScreen />} />
						</Routes>
					</div>
				</SideNavLayout>
			</BrowserRouter>
		</Suspense>
	);
}

export default AdminNavigator;
