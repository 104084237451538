import toast from "react-hot-toast";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { approveJobByID, deleteJobByID, fetchPendingJobs } from "../admin/api/jobs";
import { getAllJobs, getJobBySlug, postAJob } from "../api/jobs";

const jobKeys = { all: ["jobs"], pending: () => [...jobKeys.all, "pendingJobs"] };

export function useJobs(options = {}) {
	return useInfiniteQuery({
		queryKey: jobKeys.all,
		queryFn: ({ pageParam = 1 }) => getAllJobs(pageParam),
		getNextPageParam: (pageParam) => {
			return pageParam.nextPage;
		},
		...options,
	});
}

export function usePendingJobs(options = {}) {
	return useInfiniteQuery({
		queryKey: jobKeys.pending(),
		queryFn: ({ pageParam = 1 }) => fetchPendingJobs(pageParam),
		getNextPageParam: (pageParam) => {
			return pageParam.nextPage;
		},
		...options,
	});
}

export function useJobBySlug(options = {}) {
	const { slug } = useParams();

	return useQuery({
		queryKey: ["job", slug],
		queryFn: () => getJobBySlug(slug),
		...options,
	});
}

export function usePostJob(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(postAJob, {
		...options,
		onSuccess: () => {
			if (options.onSuccess) options.onSuccess();
			queryClient.invalidateQueries(jobKeys.pending());
		},
		onError: () => {
			toast.error("Something went wrong");
		},
	});
}

export function useApproveJob(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(approveJobByID, {
		...options,
		onSuccess: () => {
			if (options.onSuccess) options.onSuccess();
			queryClient.invalidateQueries(jobKeys.all);
		},
		onError: () => {
			toast.error("Something went wrong");
		},
	});
}

export function useDeleteJob(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(deleteJobByID, {
		...options,
		onSuccess: () => {
			if (options.onSuccess) options.onSuccess();
			queryClient.invalidateQueries(jobKeys.all); //FIXME: This isn't refreshing the cache
		},
		onError: () => {
			toast.error("Something went wrong");
		},
	});
}
