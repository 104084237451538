import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import CommunityGuidelines from "../screens/CommunityGuidelines";
import LoginScreen from "../screens/LoginScreen";
import Onboarding from "../screens/Onboarding";
import TermsAndConditions from "../screens/TermsAndConditions";

function AuthNavigator() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
				<Route path="/community-guidelines" element={<CommunityGuidelines />} />
				<Route path="/onboarding" element={<Onboarding />} />
				<Route path="*" element={<LoginScreen />} />
			</Routes>
		</BrowserRouter>
	);
}

export default AuthNavigator;
