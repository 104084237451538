import React from "react";
import { Link } from "react-router-dom";

import { dateFormat, isValidEmail } from "../../handlers/functions";

function JobPost({ job }) {
	return (
		<Link to={`/j/${job.slug}`}>
			<div className="rounded bg-white p-3 shadow-sm mb-2 hover:text-black">
				<div className="flex items-center justify-between mb-2">
					<h4 className="mb-0">{job.title}</h4>
					<a
						href={isValidEmail(job.url) ? `mailto:${job.url}` : job.url}
						className="btn button button-alt btn-sm"
						target="_blank"
						rel="noopener noreferrer"
					>
						Apply <i className="fa-solid fa-arrow-up-right-from-square" />
					</a>
				</div>
				<div className="">
					<div className="left">
						<div>
							<i className="fa-solid mr-2 fa-building" /> {job.company}
						</div>
						<div>
							<i className="fa-solid mr-2 fa-location-dot" /> {job.location}
						</div>
						<div>
							<i className="fa-solid mr-2 fa-clock" /> {job.type}
						</div>
						<div>
							<i className="mr-2 fa-solid fa-calendar-days" /> {dateFormat(job.applicationDeadline)}
						</div>
					</div>
					<div className="right text-end">
						<div>
							<strong>Posted on </strong>
							{dateFormat(job.timestamp)}
						</div>
						<p>
							<strong>Posted by Alumni</strong> {job.user.username.split(" ")[0]}
						</p>
					</div>
				</div>

				<Link className="bg-ocean-500 text-white btn-sm" to={`/j/${job.slug}`}>
					Read More
				</Link>
			</div>
		</Link>
	);
}

export default JobPost;
