import React from "react";

import NotFound from "../assets/images/undraw_page_not_found_re_e9o6.svg";

function NotFoundScreen() {
	return (
		<div className="not-found">
			<div className="empty_list">
				<img src={NotFound} alt="This page doesn't exist" />
				<p>Looks like this page doesn&#39;t exist or has been moved.</p>
			</div>
		</div>
	);
}

export default NotFoundScreen;
