import axios from "axios";
import QueryString from "qs";

import authStorage from "../../auth/storage";
import { FRONTEND_API_KEY } from "../../config/api";
import { API_BASE_URL } from "../../config/constants";

const storedUser = authStorage.getUser();

export function checkIfSetupCompleted(callback) {
	const config = {
		method: "get",
		url: API_BASE_URL + "/setup",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			"X-API-Key": FRONTEND_API_KEY,
		},
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function handleSetup(authData, callback) {
	const data = QueryString.stringify(authData);

	const config = {
		method: "post",
		url: API_BASE_URL + "/setup",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}

export function handleLogin(authData, callback) {
	const data = QueryString.stringify(authData);

	const config = {
		method: "post",
		url: API_BASE_URL + "/login",
		headers: {
			"X-API-Key": FRONTEND_API_KEY,
			"Content-Type": "application/x-www-form-urlencoded",
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			//TODO use sentry here to catch common errors
			callback(error);
		});
}

export function changePassword(authData, callback) {
	const data = QueryString.stringify(authData);

	const config = {
		method: "patch",
		url: API_BASE_URL + "/user/password",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: "Bearer " + storedUser.token,
			"X-API-Key": FRONTEND_API_KEY,
		},
		data: data,
	};

	axios(config)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			callback(error);
		});
}
