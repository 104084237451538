import Api from "../../config/api";

export async function fetchPendingEvents(page) {
	const { data } = await Api.get(`/events/pending?page=${page}`);
	return data;
}

export async function approveEventByID(id) {
	const { data } = await Api.patch("/events?eventID=" + id);
	return data;
}

export async function deleteEventByID(id) {
	const { data } = await Api.delete("/events?eventID=" + id);
	return data;
}
