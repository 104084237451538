import React from "react";

function TermsAndConditions() {
	return (
		<div className="container">
			<div className="bg-white rounded p-3 shadow-2 mt-3">
				<h1>Terms and Conditions</h1>
				<h2>DATA PRIVACY POLICY</h2>
				<ol className="terms">
					<li>Purpose</li>
					<p>
						We at St Jude’s Connect (“the portal”) we take the privacy of our users seriously. This Privacy Policy
						explains how we collect, use, and disclose information when you use our community and related services
						(collectively, the “Services”).
					</p>
					<p>
						This policy applies to all alumni associated with St Jude’s Connect (collectively referred to as the
						“Alumni”), namely:
					</p>
					<p>
						<strong>
							We take our privacy obligations seriously, and this policy sets out how we collect and handle personal
							information and how we will deal with privacy concerns.
						</strong>
					</p>
					<li>Principles</li>
					<p>
						We take our privacy obligations seriously, and this policy sets out how we collect and handle personal
						information and how we will deal with privacy concerns.
					</p>
					<p>
						<strong>The School will:</strong>
					</p>
					<ul>
						<li>Collect only information which we require for our primary purposes;</li>

						<li>
							Ensure that stakeholders are informed as to why we collect the information and how we administe and use
							the information gathered;
						</li>

						<li>
							Use and disclose personal information only for our primary purposes or a directly related purpose or for
							another purpose with the particular user’s consent;
						</li>

						<li>Store personal information securely, protecting it from unauthorised access; and</li>

						<li>
							Provide stakeholders with access to their own information and the right to seek its correction, amendments
							and updates of their data upon written consent, using official formal communication known by the School.
						</li>
					</ul>
					<p>
						<strong>The School will not:</strong>
					</p>
					<ul>
						<li>Use any data or information which is not relevant to the stakeholder</li>
					</ul>
					<li>Scope</li>
					<p>
						This policy applies to our staff, board members, volunteers, Consultants and who handle personal information
						collected by the School and it applies to personal information collected from the Alumni.
					</p>
					<p>
						By providing your personal information to us, you consent to the use, storage and disclosure of the personal
						information you provide the School as described in this policy.
					</p>
					<li>What personal information do we collect and hold?</li>
					<p>We may collect the following types of personal information:</p>
					<ul>
						<li>
							Name, mailing or street address, email address, telephone number, age or date of birth, gender or other
							verification information
						</li>
						<li>Profession, occupation and employment details;</li>
						<li>Education details</li>
						<li>Photograph, video or audio recording;</li>
						<li>Content data of chats, tickets and threads</li>
						<li>Social media usernames and links</li>
						<li>Uploaded files as defined in the Community guidelines</li>
						<li>
							As well as any additional information relating to you that you provide to us directly or indirectly.
						</li>
					</ul>
					<li>How do we collect your personal information?</li>
					<p>
						We collect your personal information directly from you unless it is unreasonable or impracticable to do so.
						We may collect personal information from you in the following ways:
					</p>
					<ul>
						<li>Through your access and use of our portal;</li>
						<li>Through surveys; or</li>
						<li>During conversations between you and our staff.</li>
					</ul>
					<li>Cookies</li>
					<p>
						In some cases we may also collect your personal information through the use of cookies. We also use cookies
						to measure traffic patterns, determine which areas of our portal have been visited. We use this to research
						our users’ habits so that we can improve our online experience. If you do not wish to receive cookies, you
						can set your browser so that your computer does not accept them.
					</p>
					<li>For what purposes do we collect, hold, use and disclose your personal information?</li>
					<p>We collect personal information as outlined in the principles:</p>
					<ul>
						<li>to send communications requested by you;</li>
						<li>to assess and improve the performance of the portal</li>
						<li>
							for the administrative, marketing (including direct marketing), planning, product or service development,
							quality control and research purposes of the School and the association;
						</li>
						<li>to update our records and keep your contact details up to date;</li>
						<li>to process and respond to any enquiry or complaint made by you; and</li>
						<li>
							to comply with any law, rule, regulation, lawful and binding determination, decision or direction of a
							regulator, or in co-operation with any governmental authority of any country.
						</li>
					</ul>
					<p>
						Your personal information will not be shared, sold, rented or disclosed other than as described in this
						policy.
					</p>
					<li>To whom may we disclose your information?</li>
					<p>We may disclose your personal information to:</p>
					<ul>
						<li>Our staff, board members, students, volunteers;</li>

						<li>Consultants or service providers for fundraising or operating the School or our Portal;</li>

						<li>
							Service Providers including and without limitation to; web hosting providers, IT systems administrators,
							mailing houses, couriers, payment processors, data entry service providers, electronic network
							administrators, and professional advisors; or
						</li>

						<li>any organisation for any authorised purpose with your express consent.</li>
					</ul>
					<li>Direct communications</li>
					<p>
						We will send you direct communications and information on relevant updates to your profile on the portal and
						any other communications you have opted into. These can be managed within the setting of your profile.
					</p>
					<p>We do not provide your personal information to other organisations for direct marketing purposes.</p>
					<p>
						The School adheres to Rules and Regulations set to ensure that it confirms both at National and
						International level with Cybercrime Acts, Spam Acts and Personal Data protection Policies.
					</p>
					<li>What is the process for complaining about a breach of privacy?</li>
					<p>
						If you believe that your privacy has been breached, please get in touch with us using the contact
						information below and provide details of the incident so that we can investigate it.
					</p>
					<p>
						We request that complaints about privacy breaches be made in writing, so we can be sure about the details of
						the complaint using the contact information below. We will attempt to confirm as appropriate and necessary
						with you your understanding of the conduct relevant to the complaint and what you expect as an outcome. We
						will inform you whether we will conduct an investigation, the name, title, and contact details of the
						investigating officer and the estimated completion date for the investigation process.
					</p>
					<p>
						After we have completed our enquiries, we will contact you, usually in writing, to advise the outcome and
						invite a response to our conclusions about the complaint.
					</p>
					<p>If we receive a response from you, we will assess it and advise if we have changed our view.</p>
					<li>Overseas disclosure</li>
					<p>
						We store personal information that we collect about you in overseas locations including overseas or cloud
						based data hosting facilities. The main locations we store personal information are Australia, Tanzania and
						the USA. You are taken to expressly agree and consent to the transfer, storing or processing of your
						personal information in these countries in submitting it to us.
					</p>
					<p>
						We take reasonable steps to ensure that the overseas recipients of your personal information do not breach
						the privacy obligations relating to your personal information and that they adhere to this policy.
					</p>
					<li>Security</li>
					<p>
						We take reasonable steps to ensure your personal information is protected from misuse and loss and from
						unauthorised access, modification or disclosure. We may hold your information in either electronic or hard
						copy form. Personal information is destroyed or de-identified when no longer needed.
					</p>
					<li>Links</li>
					<p>
						Our portal may contain links to other websites operated by third parties. We make no representations or
						warranties concerning the privacy practices of any third party website and are not responsible for the
						privacy policies or the content of any third-party website. Third-party websites are responsible for
						informing you about their own privacy practices.
					</p>
					<li>Contacting us</li>
					<p>
						If you have any questions about this policy, any concerns or a complaint regarding the treatment of your
						privacy or a possible breach of your privacy, please use the contact link on our website or contact us at
						<a href="mailto:alumni@schoolofstjude.co.tz">alumni@schoolofstjude.co.tz</a>
					</p>
					<p>
						We will treat your requests or complaints confidentially. Our representative will contact you within a
						reasonable time after receipt of your complaint to discuss your concerns and outline options regarding how
						they may be resolved. We will aim to ensure that your complaint is resolved in a timely and appropriate
						manner.
					</p>
					<li>Changes to our privacy policy</li>
					<p>
						We may change this privacy policy from time to time. Any updated versions of this privacy policy will be
						posted on our website. Please review it regularly.
					</p>
				</ol>
			</div>
		</div>
	);
}

export default TermsAndConditions;
