import toast from "react-hot-toast";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { approveEventByID, deleteEventByID, fetchPendingEvents } from "../admin/api/events";
import { addEvent, fetchEvents, getEventsBySlug } from "../api/events";

const eventKeys = { all: ["events"], pending: () => [...eventKeys.all, "pendingEvents"] };

export function useCreateEvent(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(addEvent, {
		...options,
		onSuccess: () => {
			if (options.onSuccess) options.onSuccess();
			queryClient.invalidateQueries(eventKeys.pending());
		},
		onError: (error) => {
			toast.error(error);
		},
	});
}

export function useEvents(options = {}) {
	return useInfiniteQuery({
		queryKey: eventKeys.all,
		queryFn: ({ pageParam = 1 }) => fetchEvents(pageParam),
		getNextPageParam: (pageParam) => {
			return pageParam.nextPage; //TODO: check if this is working, it get page 2 but not 3 and so on
		},
		...options,
	});
}

export function useEventBySlug(options = {}) {
	const { slug } = useParams();

	return useQuery({
		queryKey: [slug],
		queryFn: () => getEventsBySlug(slug),
		...options,
	});
}

export function usePendingEvents(options = {}) {
	return useInfiniteQuery({
		queryKey: eventKeys.pending(),
		queryFn: ({ pageParam = 1 }) => fetchPendingEvents(pageParam),
		getNextPageParam: (pageParam) => {
			return pageParam.nextPage;
		},
		...options,
	});
}

export function useApproveEvent(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(approveEventByID, {
		...options,
		onSuccess: () => {
			if (options.onSuccess) options.onSuccess();
			queryClient.invalidateQueries(eventKeys.all);
		},
		onError: (error) => {
			toast.error(error);
		},
	});
}

export function useDeleteEvent(options = {}) {
	const queryClient = useQueryClient();

	return useMutation(deleteEventByID, {
		...options,
		onSuccess: () => {
			if (options.onSuccess) options.onSuccess();
			queryClient.invalidateQueries(eventKeys.all);
		},
		onError: (error) => {
			toast.error(error);
		},
	});
}
