import React from "react";

function FormInput({ label, type, value, onChange, placeholder, ...props }) {
	return (
		<div className="mb-3">
			<label className="form-label">{label}</label>
			<input
				type={type}
				className="form-control"
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				{...props}
			/>
		</div>
	);
}

export default FormInput;
