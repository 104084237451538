import axios from "axios";

import authStorage from "../auth/storage";
// eslint-disable-next-line no-undef
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// eslint-disable-next-line no-undef
export const FRONTEND_API_KEY = process.env.REACT_APP_FRONTEND_API_KEY;

const storedUser = authStorage.getUser();

const Api = axios.create({
	baseURL: API_BASE_URL,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
		"X-API-Key": FRONTEND_API_KEY,
		...(storedUser?.token && { Authorization: `Bearer ${storedUser?.token}` }),
	},
});

export default Api;
